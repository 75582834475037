<template>
    <div class="agendaroom-offline">
        <CompTable isSelector ref="comp_table" title="线下议事记录" :columns="columns" :tableApi="tableApi">
            <template #operate>
                <RadioGroup v-if="discuss" v-model="activity" type="button" button-style="solid">
                    <Radio :label="0">屏蔽</Radio>
                    <Radio :label="1">显示</Radio>
                </RadioGroup>
                <Button type="info" @click="$refs.comp_form.open()">新增</Button>
            </template>
        </CompTable>

        <CompForm
            ref="comp_form"
            :forms="forms"
            detail-api="/gateway/syinfopublish/api/app/chamber/getIssueUserInfo"
            submit-api="/gateway/syinfopublish/api/app/chamber/createChamberIssue"
            edit-api="/gateway/syinfopublish/api/app/chamber/updateChamberIssue"
            id-key="issueId"
            :dataBefore="onDataBefore"
            :submit-before="onSubmitBefore"
            @on-submit="$refs.comp_table.refresh()"
        ></CompForm>

        <ProcessForm ref="process_form"></ProcessForm>
    </div>
</template>

<script>
import CompTable from "../../jointly/components/comp-table"

import Route from "../../communitymobilization/entity/Route"
import Request from "../../jointly/utils/request"
import Utils from "../../jointly/utils/utils"
import CompForm from "../../jointly/components/CompForm.vue"

import ProcessForm from "./ProcessForm.vue"

export default {
    components: { CompTable, CompForm, ProcessForm },

    data() {
        return {
            tableApi: null,
            types: {},
            columns: [
                {
                    title: "议事主题",
                    key: "title",
                },
                {
                    title: "议题类型",
                    render: (h, params) => this.types[params.row.issueType] || "未知",
                },
                {
                    title: "开始时间",
                    render: (h, params) => this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd"),
                },
                {
                    title: "基层协商参与度",
                    width: 250,
                    render: (h, params) => {
                        return h(
                            "Select",
                            {
                                props: {
                                    value: params.row.participation,
                                },
                                style: {
                                    width: "200px",
                                },
                                on: {
                                    "on-change"(evt) {
                                        Request.post(
                                            "/gateway/syinfopublish/api/pc/chamber/updateIssue",
                                            {
                                                issueId: params.row.id,
                                                participation: evt,
                                            },
                                            {
                                                success: "修改成功",
                                                json: true,
                                            }
                                        )
                                    },
                                },
                            },
                            this.consultation?.map(v =>
                                h(
                                    "Option",
                                    {
                                        attrs: {
                                            value: v.dictKey,
                                        },
                                    },
                                    v.dictValue
                                )
                            )
                        )
                    },
                },
                {
                    float: "right",
                    title: "操作",
                    width: 320,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Route.jump("/agendaroomofflinedetail", { id: params.row.id })
                                        },
                                    },
                                },
                                "查看"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "success",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.comp_form.open(params.row.id)
                                        },
                                    },
                                },
                                "编辑"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.onDelete(params.row.id)
                                        },
                                    },
                                },
                                "删除"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "primary",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.process_form.display(null, null, params.row.id)
                                        },
                                    },
                                },
                                "添加过程资料"
                            ),
                        ])
                    },
                },
            ],

            forms: null,
            // 协商参与度类型
            consultation: null,
        }
    },

    async created() {
        const res = await Request.get("/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
            dictType: "ISSUE_TYPE",
            appCode: "",
        })

        const types = {}

        Utils.each(res, v => {
            types[v.dictKey] = v.dictValue
        })

        this.types = types
        // 表格接口
        this.tableApi = `/gateway/syinfopublish/api/pc/chamber/listChamberIssueFlowByPage?orgCode=${parent.vue.loginInfo.userinfo.communityCode}&channel=1`
        // 获取协商参与度类型
        await this.getConsultation()
        // 设置表单结构
        this.setForms(res)
    },

    methods: {
        getConsultation() {
            return Request.get("/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "ISSUE_CONSULTATION",
                appCode: "",
            }).then(res => {
                this.consultation = res
            })
        },

        setForms(issueTypes) {
            this.forms = [
                {
                    type: "input",
                    title: "标题",
                    name: "title",
                    required: true,
                },
                {
                    type: "select",
                    title: "议题类型",
                    name: "issueType",
                    required: true,
                    options: issueTypes.map(v => ({
                        value: v.dictKey,
                        label: v.dictValue,
                    })),
                },
                {
                    type: "datePicker",
                    title: "开展时间",
                    name: "processTime",
                },
                {
                    type: "input",
                    title: "开展地点",
                    name: "processAddr",
                },
                {
                    type: "select",
                    title: "基层协商参与度",
                    name: "participation",
                    options: this.consultation.map(v => ({
                        value: v.dictKey,
                        label: v.dictValue,
                    })),
                },
                {
                    type: "textarea",
                    title: "详情",
                    name: "content",
                    required: true,
                    contenteditable: true,
                    height: "500px",
                },
            ]
        },

        onDelete(id) {
            this.$Modal.confirm({
                title: "提示",
                content: "您正在删除数据，是否继续？",
                onOk: () => {
                    this.$get("/gateway/syinfopublish/api/pc/chamber/deleteIssueById", {
                        issueId: id,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            // 刷新表格数据
                            this.$refs.comp_table.refresh()
                        } else {
                            this.$Message.error({
                                content: res.desc || "删除失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },

        onDataBefore(data) {
            if (data.processTime) {
                data.processTime = new Date(data.processTime)
            }
            return data
        },

        onSubmitBefore(data) {
            return {
                ...data,
                processTime: data.processTime ? new Date(`${data.processTime} 00:00`).getTime() : null,
                createBy: parent.vue.loginInfo.userinfo.userName,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.communityCode,
                channel: 1,
            }
        },
    },
}
</script>
<style lang="less">
.agendaroom-comment {
    .menu-bar-box {
        height: 45px;
        margin: 10px 10px 20px 10px;

        .menu-box {
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid #f3f3f3;
            background: #fff;
            border-radius: 5px;
            display: flex;

            .slider {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 50%;
                transition: all 0.3s ease;

                &::after {
                    content: "";
                    background: #2faaf7;
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    bottom: 5px;
                    right: 5px;
                    border-radius: 3px;
                }
            }

            > .item-box {
                position: relative;
                height: 100%;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                padding: 0 20px;
            }

            > .activity {
                color: #fff;
            }
        }
    }
}
</style>
