<template>
    <CompForm ref="process_form" title="过程资料" :forms="processForms" submit-api="/gateway/syinfopublish/api/pc/chamber/saveProcess" id-key="id" :submit-before="onSubmitProcessBefore" @on-submit="$emit('on-submit', { tag: 'ProcessForm' })"></CompForm>
</template>

<script>
import CompForm from "../../jointly/components/CompForm.vue"

export default {
    components: { CompForm },

    data() {
        return {
            // 过程资料表单
            processForms: [
                {
                    type: "input",
                    title: "标题",
                    name: "title",
                    required: true,
                },
                {
                    type: "datePicker",
                    dateType: "datetimerange",
                    title: "开展时间",
                    name: "date",
                    required: true,
                },
                {
                    type: "textarea",
                    title: "详情",
                    name: "content",
                    required: true,
                    contenteditable: true,
                    height: "500px",
                },
                {
                    type: "label",
                    title: "参与人员（回车键分隔）",
                    name: "participate",
                },
                {
                    type: "upload",
                    title: "图片",
                    name: "processImg",
                    max: 9,
                },
                {
                    type: "upload",
                    title: "文档",
                    name: "processFile",
                    resource_type: "file",
                    max: 9,
                },
                {
                    type: "input",
                    title: "直播回放链接",
                    name: "liveReplayUrl",
                },
            ],
        }
    },

    methods: {
        display(id, data, extra) {
            this.$refs.process_form.display(id, data, extra)
        },

        onSubmitProcessBefore(data, isEdit, extra) {
            data = JSON.parse(JSON.stringify(data))

            data.startDate = data.date ? new Date(data.date[0]).getTime() : ""
            data.endDate = data.date ? new Date(data.date[1]).getTime() : ""

            delete data.date

            return {
                ...data,
                issueId: data.issueId || extra,
                participate: data.participate ? JSON.stringify(data.participate) : "",
                processImg: data.processImg ? JSON.stringify(data.processImg) : "",
                processFile: data.processFile ? JSON.stringify(data.processFile) : "",
            }
        },
    },
}
</script>
